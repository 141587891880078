<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col md="9" sm="12" class="mt-0">
        <b-row class="match-height">
          <!-- <b-col lg="6" md="6">
                        <ecommerce-earnings-chart :data="data.earningsChart || {}" />
                    </b-col> -->
          <b-col sm="12" lg="3" md="6">
            <ecommerce-order-chart :data="dashboard.bankbalance || 0" />
          </b-col>
          <b-col sm="12" lg="3" md="6">
            <ecommerce-profit-chart :data="dashboard.creditcards || 0" />
          </b-col>
          <b-col sm="12" lg="6" md="12">
            <ecommerce-statistics :data="dashboard.stats || {}" />
          </b-col>
          <b-col sm="12" lg="8" md="6">
            <ecommerce-company-table
              :table-data="dashboard.alerts ? dashboard.alerts.data : [] || []"
            />
          </b-col>
          <b-col sm="12" lg="4" md="6">
            <ecommerce-meetup
              :dashboard="dashboard.updob"
              :data="data.meetup || []"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3" sm="12" class="mt-2">
        <div class="dash-stats px-1">
          <div class="row illus">
            <div class="col-9 pt-3 mb-n2">
              <img
                :src="require('@/assets/images/custom/dash.svg')"
                alt=""
                class="w-100"
              />
            </div>
          </div>
          <h5 class="mt-3">
            <b>{{ $t("fields.accomplished") }}</b>
          </h5>
          <h1 class="font-bold" style="font-weight: 900">
            {{
                accomplishedp
            }}% 
          </h1>
          <h6 v-if="comp.length > 0" class="mt-2">Pending</h6>
          <ul v-if="comp.length > 0" class="">
            <router-link
              v-for="(item, index) in comp"
              :key="'completness' + index"
              :to="item.link"
              :class="''"
            >
              <li>
                <img
                  :src="require('@/assets/images/custom/no.svg')"
                  alt=""
                  class=""
                />
                {{ $t(item.id) }}
              </li>
            </router-link>
          </ul>

          <h6 class="mt-1">{{ $t("fields.summary") }}</h6>
          <ul class="">
            <div
              v-for="(item, index) in acceptlist"
              :key="'acceptlist' + index"
            >
              <li>
                <a href="">
                  <img
                    :src="require('@/assets/images/custom/yes.svg')"
                    alt=""
                    class=""
                  />
                  {{ $t(item.name) }}
                </a>
              </li>
            </div>
          </ul>
          <!-- <div
            class="px-1 mt-1"
            v-for="(item, index) in acceptlist"
            :key="'acceptlist' + index"
          >
            <h5>
              <feather-icon size="18" icon="CheckIcon" class="mr-50" />{{
                dashboard[item.value] || 0
              }}
              {{ $t(item.name) || "" }}
            </h5>
          </div> -->
        </div>
        <div class="mt-2 dash-stats px-1">
          <h6 class="mt-1">Storage Used</h6>
          <div class="progress-wrapper">
            <b-progress v-model="dashboardstorage" max="100" />
            <div>
              <div class="float-right">
                {{ niceBytes(dashboard.storage || 0) }}
              </div>
              {{ niceBytes(dashboard.uploadstoragesize || 0) }}
            </div>
          </div>
          <table class="table p-0 mt-2">
            <tr class="p-0">
              <th>{{ $t("fields.numoffile") }}</th>
              <th>{{ dashboard.nooffiles || 0 }}</th>
            </tr>
            <tr>
              <th>{{ $t("fields.usedstorage") }}</th>
              <th>
                {{ niceBytes(dashboard.uploadstoragesize || 0) }}
              </th>
            </tr>
          </table>
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMediaAside,
  BProgress,
  BCard,
  BBadge,
  BRow,
  BCol,
  VBTooltip,
  BButton,
  BImg,
  BTable,
  BMedia,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import StoreModule from "./StoreModule";
import { ref, onUnmounted } from "@vue/composition-api";
import { getUserData } from "@/auth/utils";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";
import { useToast } from "vue-toastification/composition";
export default {
  data() {
    return {};
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMediaAside,
    BCard,
    BBadge,
    BRow,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    BProgress,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    BCol,
    VBTooltip,
    BButton,
    BImg,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      data: {},
      acceptlist: [
        {
          name: "navigation.realestate",
          value: "overviewrealestate",
          link: "physicalassets_realestate",
        },
        {
          name: "fields.creditcards",
          value: "overviewcreitcard",
          link: "financialassets_card",
        },
        {
          name: "fields.bankaccounts",
          value: "overviewbank",
          link: "financialassets_bank",
        },
        {
          name: "navigation.transaction",
          value: "overviewtransaction",
          link: "transaction",
        },
        {
          name: "navigation.noofdays",
          value: "noofdays",
          link: null,
        },
      ],
    };
  },
  created() {
    const userData = getUserData();
    if(!userData){
      this.$router.push({ name: "auth-login" });
    }
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;
      this.data.congratulations.name = userData.name;
    });
  },
  setup(props, { emit }) {
    const completness = [
      {
        id: "fields.nominee",
        link: "/settings/profile",
      },
      {
        id: "navigation.realestate",
        link: "/physicalassets/realestate",
      },
      {
        id: "navigation.householeqipment",
        link: "/physicalassets/householdequipments",
      },
      {
        id: "navigation.automobile",
        link: "/physicalassets/automobile",
      },
      {
        id: "navigation.preciousmet",
        link: "/physicalassets/preciousmetals",
      },
      {
        id: "navigation.bank",
        link: "/financialassets/bank",
      },
      {
        id: "fields.wallet",
        link: "/financialassets/wallet",
      },
      {
        id: "navigation.cash",
        link: "/financialassets/cash",
      },
      // {
      //     id: 'navigation.bankcards',
      //     link: '/financialassets/bankcards',
      // },
      {
        id: "navigation.loan",
        link: "/liabilities/loan",
      },
      {
        id: "navigation.importantcontacts",
        link: "/assistance/importantcontacts",
      },
      {
        id: "navigation.supportstaff",
        link: "/assistance/supportstaff",
      },
      {
        id: "navigation.water",
        link: "/utilities/water",
      },
      {
        id: "navigation.electricity",
        link: "/utilities/electricity",
      },
      {
        id: "navigation.cabletvdth",
        link: "/utilities/cabletvdth",
      },
      {
        id: "navigation.mobile",
        link: "/utilities/mobile",
      },
      {
        id: "navigation.landbroad",
        link: "/utilities/landbroad",
      },
      {
        id: "navigation.gas",
        link: "/utilities/gas",
      },
      {
        id: "navigation.retirals",
        link: "/financialassets/retirals",
      },
      {
        id: "navigation.mutualfunds",
        link: "/financialassets/mutualfunds",
      },
      {
        id: "navigation.inslife",
        link: "/insurance/life",
      },
      {
        id: "navigation.insgeneral",
        link: "/insurance/general",
      },
    ];
    var comp = [];
    const userData = getUserData();
    const GENAPP_APP_STORE_MODULE_NAME = "dashboard";
    const modulename = "Dashboard";
    const modulenamesub = "dashboard";
    const units = ["b", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    const dashboardstorage = ref(0);
    const appData = ref({});
    const niceBytes = (x) => {
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    };
    const accomplishedp= ref(0)
    const dashboard = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchedascboarddata")
      .then((response) => {
        var temp = 0;
        dashboard.value = response.data;
        for (var i = 0; i < dashboard.value.completness.length; i++) {
          if (dashboard.value.completness[i] == 0) {
            if(temp<5){
              comp.push(completness[i]);
            }
            temp++;
          }
        }

        accomplishedp.value=(dashboard.value.completness.length-temp)/dashboard.value.completness.length
        accomplishedp.value=(parseFloat(accomplishedp.value).toFixed(2)*100)
        
        dashboardstorage.value = Math.round(
          (dashboard.value.uploadstoragesize / dashboard.value.storage) * 100
        );
      });
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      accomplishedp,
      appData,
      modulename,
      modulenamesub,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      dashboard,
      userData,
      niceBytes,
      completness,
      dashboardstorage,
      comp,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.dash-stats {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.illus {
  background-color: #eeecfe;
}
.mb-n2 {
  margin-bottom: -2rem;
}
.dash-stats ul {
  margin: 0;
  list-style: none;
  padding: 0;
}
.dash-stats ul a {
  display: block;
  margin-top: 0.25rem;
  color: #4b4b4b;
}
.dash-stats ul img {
  width: 1rem;
  margin-right: 0.25rem;
}
</style>
