<template>
  <b-card class="card-congratulation-medal">
    <h5>{{ $t("fields.congratulations") }} 🎉 {{ data.name }}!</h5>
    <b-card-text class="font-small-3">
      <b-dropdown
        text="Nominee"
        size="sm"
        class="budget-dropdown"
        variant="outline-primary"
      >
        <b-dropdown-item v-for="nomine in data.nominee" :key="nomine">
          {{ data.nomine }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-text>
    <h3 class="mb-75 mt-2 pt-50">
      <b-link>
        <span style="font-family: rupee; font-size: 17px" class="fw-bolder"
          >&#8377;</span
        >
        {{ kFormatter(data.saleToday) }}</b-link
      >
    </h3>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
      {{ $t("fields.viewnotifications") }}
    </b-button>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>
<!--
<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
  },
}
</script> -->


<script>
import {
  BCard,
  BCardText,
  BDropdown,
  BLink,
  BButton,
  BImg,
} from "bootstrap-vue";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import StoreModule from "./StoreModule";
import { ref, onUnmounted } from "@vue/composition-api";
import { kFormatter } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";

import { useToast } from "vue-toastification/composition";
export default {
  data() {
    return {};
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BCard,
    BCardText,
    BDropdown,
    BLink,
    BImg,
    BButton,
  },

  data() {
    return {};
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "dashboard";

    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    const appData = [];

    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      store.dispatch(
        GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
        edittype == 0 ? formData : jx
      );
    };
    const dashboard = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchedascboarddata")
      .then((response) => {
        dashboard.value = response.data;
      });

    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      appData,
      onSubmit,
      key,
      dashboard,
    };
  },
  mounted() {},
  methods: {
    kFormatter,
  },
};
</script>
