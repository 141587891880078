<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table w-100"
    style="height: 50vh; overflow-y: scroll"
  >
    <b-table
      v-if="tableData.length > 0"
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0 w-100"
    >
      <template #cell(module)="data">
        <div class="d-flex align-items-center">
          {{ $t(data.item.module) }}
        </div>
      </template>
      <template #cell(information)="data">
        <div class="d-flex align-items-left">
          <router-link
            v-if="data.item.module == 'physicalassets_realestate'"
            :to="'/physicalassets/realestate/view/' + data.item.moduledata_id"
          >
            {{ $t("fields.propertytaxfina") }}
            {{
              data.item.moduledata.data
                ? data.item.moduledata.data.paidyear
                : ""
            }}
            -
            {{
              data.item.moduledata.data
                ? data.item.moduledata.data.paidfinanceyear
                : ""
            }}
            {{ $t("fields.isdue") }}.
          </router-link>
          <router-link
            v-else-if="data.item.module == 'physicalassets_householdequipments'"
            :to="
              '/physicalassets/householdequipments/view/' +
              data.item.moduledata_id
            "
          >
            <span v-if="data.item.type == 'warantyupto'">
              {{ $t("fields.thistoremindthat") }}
              {{
                data.item.moduledata.data
                  ? data.item.moduledata.data.category
                  : ""
              }}
              {{ $t("fields.isdue") }} {{ $t("fields.forwarrantyren") }}.
            </span>
            <span v-else-if="data.item.type == 'servicedate'">
              {{ $t("fields.thistoremindthat") }}
              {{
                data.item.moduledata.data
                  ? data.item.moduledata.data.category
                  : ""
              }}
              {{ $t("fields.isdue") }} {{ $t("fields.fornextserve") }}.
            </span>
          </router-link>
          <router-link
            v-else-if="data.item.module == 'physicalassets_automobile'"
            :to="'/physicalassets/automobile/view/' + data.item.moduledata_id"
          >
            {{ $t("fields.thistoremindthat") }}
            {{
              data.item.moduledata.data ? data.item.moduledata.data.regnno : ""
            }}
            {{ $t("fields.isdue") }} {{ $t("fields.fornextserve") }}.
          </router-link>
          <router-link
            v-else-if="data.item.module == 'physicalassets_locker'"
            :to="'/physicalassets/locker/view/' + data.item.moduledata_id"
          >
            {{ $t("fields.thistoremindthat") }}
            {{
              data.item.moduledata.data ? data.item.moduledata.data.name : ""
            }}
            {{ $t("fields.itdueforreneval") }}.
          </router-link>
          <router-link
            v-else-if="data.item.module == 'assistance_health'"
            :to="'/assistance/health/view/' + data.item.moduledata_id"
          >
            {{ $t("fields.docvisitschedule") }}
            {{
              data.item.moduledata.data
                ? data.item.moduledata.data.nextvisitdate
                : ""
            }}
          </router-link>
          <router-link
            v-else-if="data.item.module == 'leisure_membership'"
            :to="'/leisure/membership/view/' + data.item.moduledata_id"
          >
            {{ $t("fields.thistoremindthat") }}
            {{
              data.item.moduledata.data
                ? data.item.moduledata.data.validupto
                : ""
            }}
            {{ $t("fields.itdueforreneval") }}.
          </router-link>
          <!-- /////This is to remind that -->
          <router-link
            v-else-if="data.item.module == 'leisure_subscriptions'"
            :to="'/leisure/subscriptions/view/' + data.item.moduledata_id"
          >
            {{
              data.item.moduledata.data
                ? data.item.moduledata.data.suscripeval
                : ""
            }}
            {{ $t("fields.itdueforreneval") }}.
          </router-link>
        </div>
      </template>
      <template #cell(duedate)="data">
        <div class="d-flex align-items-center">
          {{ data.item ? data.item.date : "" }}
        </div>
      </template>
    </b-table>
    <div v-else class="justify-content-center w-100 text-center align-middle h-100">
      <div
        class="row justify-content-center w-100 text-center align-middle align-items-center h-100"
      >
        <div class="col-6">
          <img
            :src="require('@/assets/images/custom/nodata.png')"
            alt=""
            class="w-100"
          />
          No alerts for now
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BTable, BAvatar, BImg } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: "module", label: "Module" },
        { key: "information", label: "Information" },
        { key: "duedate", label: "Due Date" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
